<template>
  <div>
    <!-- background-image: url(img/theme/profile-cover.jpg); -->
    <div
      class="header pb-6 pt-5 pt-lg-8 d-flex align-items-center profile-header"
      style="
        min-height: 150px;
        background-size: cover;
        background-position: center top;
      "
    >
      <b-container fluid>
        <!-- Mask -->
        <span style="background-color: #8f384d" class="mask opacity-8"></span>
        <!-- Header container -->
        <b-container fluid class="d-flex align-items-center"> </b-container>
      </b-container>
    </div>
    <b-container fluid class="mt--6" v-if="$store.state.username">
      <b-row>
        <b-col></b-col>
        <b-col sm="12" xl="8">
          <card>
            <b-row>
              <b-col class="text-center">
                <h1>คะแนนสอบ</h1>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="text-center mb-2">
                <h1>{{ $store.state.realtest_topic.mode }}</h1>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="text-center">
                <h2>
                  {{ totalScore + " คะแนน "
                  }}<b-icon icon="clock-fill" class="mr-1"></b-icon>{{ time }}
                </h2>
              </b-col>
            </b-row>
            <b-row v-if="$store.state.realtest_topic.mode == 'สอบอบรม'">
              <!-- <b-col class="text-success text-center mb-2">
                <h2 class="text-success" v-if="totalScore >= 60">ผ่าน<b-icon icon="emoji-sunglasses" class="ml-1"></b-icon></h2>
                <h2 class="text-danger" v-else>ไม่ผ่าน<b-icon icon="emoji-expressionless" class="ml-1"></b-icon></h2>
              </b-col> -->
            </b-row>
            <b-row v-else>
              <b-col class="text-success text-center mb-2">
                <h2 class="text-success" v-if="totalScore >= 60">
                  ผ่าน<b-icon icon="emoji-sunglasses" class="ml-1"></b-icon>
                </h2>
                <h2 class="text-danger" v-else>
                  ไม่ผ่าน<b-icon
                    icon="emoji-expressionless"
                    class="ml-1"
                  ></b-icon>
                </h2>
              </b-col>
            </b-row>
            <b-row
              class="mb-2"
              align-v="center"
              v-for="(item, index) in $store.state.realtest_topic.list"
              :key="index"
            >
              <b-col cols="9">{{ item.subject }}</b-col>
              <b-col class="text-right" cols="3"
                >{{
                  scoreByID(item.index).correct
                    ? scoreByID(item.index).correct + " คะแนน"
                    : 0 + " คะแนน"
                }}
                <b v-show="$store.state.realtest_topic.mode == 'สอบอบรม'">
                  {{
                    scoreByID(item.index).correct >= (parseInt(item.test) * 0.6)
                      ? "ผ่าน"
                      : "ไม่ผ่าน"
                  }}
                </b></b-col
              >
            </b-row>
            <b-row class="text-center mt-4">
              <b-col>
                <b-button
                  size="lg"
                  @click="toRealtest()"
                  :style="`background-color: ${testTypeColor}`"
                  ><b-icon
                    font-scale="2.3"
                    icon="arrow-repeat"
                    style="color: white"
                  ></b-icon
                ></b-button>
              </b-col>
              <b-col>
                <b-button
                  size="lg"
                  class="ni ni-bulb-61 ni-2x"
                  @click="toHistory()"
                  :style="`background-color: ${testTypeColor}`"
                  style="color: white"
                ></b-button>
              </b-col>
              <b-col>
                <b-button
                  size="lg"
                  @click="toStat()"
                  :style="`background-color: ${testTypeColor}`"
                  ><b-icon
                    font-scale="2.3"
                    icon="pie-chart-fill"
                    style="color: white"
                  ></b-icon
                ></b-button>
              </b-col>
            </b-row>
          </card>
        </b-col>
        <b-col></b-col>
      </b-row>
    </b-container>
    <loader
      v-if="isLoading"
      object="#8f384d"
      color1="#89cf99"
      color2="#90cbe9"
      size="5"
      speed="2"
      bg="#343a40"
      objectbg="#999793"
      opacity="80"
      name="circular"
    ></loader>
  </div>
</template>

<script>
import { getRealTestStatSummary } from "@/APIs";
export default {
  data() {
    return {
      summary: [[], []],
      isLoading: true,
    };
  },
  async created() {
    await this.initSummary();
  },
  methods: {
    async initSummary() {
      this.isLoading = true;
      var params = {
        username: this.$store.state.username,
        roundTest: this.$route.params.test_id,
      };
      getRealTestStatSummary(params).then((res) => {
        if (res.status === 200 && res.data.success) {
          this.summary = res.data.results;
          this.isLoading = false;
        }
      });
    },
    scoreByID(id) {
      if (this.summary) {
        for (
          let index = 0;
          index < this.summary[this.realtest.testType].length;
          index++
        ) {
          if (this.summary[this.realtest.testType][index]._id === id) {
            return this.summary[this.realtest.testType][index];
          }
        }
      }
      return 0;
    },
    toRealtest() {
      this.$router.push({ path: "/realtest" });
    },
    toHistory() {
      var params = {
        subjectIndex: null,
        startDate: "",
        endDate: "",
      };
      this.$store.commit("SET_HISTORY", params);
      this.$router.push({ path: "/history/" + this.$route.params.test_id });
    },
  },
  computed: {
    realtest() {
      return this.$store.state.realtest;
    },
    detail_topic() {
      return this.$store.state.detail_topic;
    },
    totalScore() {
      if (this.summary) {
        var score = 0;
        for (
          let index = 0;
          index < this.summary[this.realtest.testType].length;
          index++
        ) {
          score += this.summary[this.realtest.testType][index].correct;
        }
        return score;
      }
      return 0;
    },
    totalTime() {
      if (this.summary) {
        var time = 0;
        for (
          let index = 0;
          index < this.summary[this.realtest.testType].length;
          index++
        ) {
          time += this.summary[this.realtest.testType][index].totalTime;
        }
        return time;
      }
      return 0;
    },
    time() {
      return this.hours + " : " + this.minutes + " : " + this.seconds;
    },
    hours() {
      var hrs = Math.floor((this.totalTime / 3600) % 24);
      if (hrs >= 13) {
        hrs = hrs - 12;
      }
      return hrs >= 10 ? hrs : "0" + hrs;
    },
    minutes() {
      var min = Math.floor((this.totalTime - this.hours * 3600) / 60);
      return min >= 10 ? min : "0" + min;
    },
    seconds() {
      var sec = this.totalTime - this.hours * 3600 - this.minutes * 60;
      return sec >= 10 ? sec : "0" + sec;
    },
    testTypeColor() {
      return this.realtest.testType === 0 ? "#89cf99" : "#90cbe9";
    },
  },
};
</script>
